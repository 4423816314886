import { useFrontload } from 'react-frontload';
import { useEffect, useRef } from 'react';
import searchApi from '../../api/searchApi';
import { getValidResponseBody } from '../../api';
import { useAuth0 } from '../../utils/auth0';

const getSearchApiOptions = (selectedProductObject, searchId) => {
  if (!selectedProductObject) return selectedProductObject;

  const requested = ['brand', 'campaignID', 'EAN', 'family_code', 'GTIN', 'id', 'ISBN', 'SKU', 'position'];
  return requested.reduce(
    (options, prop) => {
      return { ...options, [prop]: selectedProductObject[prop] };
    },
    { type: 'pdc', search_id: searchId }
  );
};

const getProductOffersOptions = (authToken, selectedProductObject, searchId) => {
  if (!selectedProductObject) return null;
  const productShopsHeaders = authToken ? { Authorization: `Bearer ${authToken}` } : {};
  const searchApiOptionsData = getSearchApiOptions(selectedProductObject, searchId);
  return { headers: productShopsHeaders, method: 'POST', body: JSON.stringify(searchApiOptionsData) };
};

const getProductOffers = (searchApi, options) => searchApi.getProductOffers(options);

const apiDataInitial = { shopsDataBody: null };

const getApiData = async (productOffersOptions) => {
  if (!productOffersOptions) return { ...apiDataInitial };
  try {
    return { shopsDataBody: await getProductOffers(searchApi, productOffersOptions) };
  } catch {
    if (!productOffersOptions) return { ...apiDataInitial };
  }
};

const updateFrontLoadData = async (productOffersOptions, onReady) => {
  const updatedData = await getApiData(productOffersOptions);
  onReady(updatedData);
};

export const useProductDetails = (selectedProductObject = {}, searchId) => {
  const { tokenClaims } = useAuth0();
  const authToken = tokenClaims?.__raw;
  const productOffersOptions = getProductOffersOptions(authToken, selectedProductObject, searchId);
  const dataReady = useRef(false);
  const prevSelectedProduct = useRef(selectedProductObject?.id);

  const { data: frontloadData, setData: setFrontLoadData } = useFrontload('product-details-data', async () => ({
    ...apiDataInitial,
  }));

  const setDataReady = (isDataReady) => {
    dataReady.current = isDataReady;
  };

  const productChanged = prevSelectedProduct.current !== selectedProductObject?.id;

  if (productChanged) setDataReady(false);

  useEffect(() => {
    // Reload data on client side on product changes
    updateFrontLoadData(productOffersOptions, (updatedData) => {
      const isOk = !!updatedData?.shopsDataBody?.ok;
      if (isOk) prevSelectedProduct.current = selectedProductObject?.id;
      setDataReady(isOk);
      setFrontLoadData((data) => ({ ...data, ...updatedData }));
    });
  }, [authToken, selectedProductObject?.id]);

  return {
    dataReady: dataReady?.current,
    shopsDataBody: getValidResponseBody(frontloadData?.shopsDataBody),
  };
};
