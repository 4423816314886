import React from 'react';
import { propsComparison } from '../../utils/propsComparison';
import useMediaQuery from '../../utils/useMediaQuery';

const viewStatesAreEqual = (prevViewState, nextViewState) => {
  return (
    prevViewState?.isOpened === nextViewState?.isOpened &&
    prevViewState?.isOpening === nextViewState?.isOpening &&
    prevViewState?.isClosing === nextViewState?.isClosing
  );
};

const pliPropsAreEqual = (prevProps, nextProps) => {
  return (
    prevProps.selectedProductIndex === nextProps.selectedProductIndex &&
    prevProps.productsList?.length === nextProps.productsList?.length &&
    propsComparison(prevProps.productsList, nextProps.productsList) &&
    prevProps.offline === nextProps.offline &&
    viewStatesAreEqual(prevProps.detailsCard?.props.viewState, nextProps.detailsCard?.props.viewState)
  );
};

const ProductsListItems = React.memo(
  ({ selectedProductIndex, productsList, detailsCard, classList, isTilesView, isTwoTilesView, isVideoPresent }) => {
    const { isGreaterOrEqualTo: atLeast, BREAKPOINTS } = useMediaQuery();
    const { MEDIUM: m, LARGE: l, EXTRA_EXTRA_LARGE: xxl } = BREAKPOINTS;

    const tilesInRow = atLeast(xxl) ? 5 : atLeast(m) ? 3 : 2;
    const columnsInRow = atLeast(m) ? 2 : 1;
    const productsInRow = isTwoTilesView ? 2 : isTilesView ? tilesInRow : columnsInRow;

    const getCardPosition = () => {
      const row = Math.ceil((selectedProductIndex + 1) / productsInRow);
      const detailsCardPosition = row * productsInRow;
      return detailsCardPosition;
    };

    const getCardPositionWithVideo = () => {
      //Video tile takes 2x of product tile some times, but is a list item at the same time
      const tilesToSubstract = 2;
      const columnsToSubstract = atLeast(l) ? 1 : atLeast(m) ? 2 : 1;
      const firstRowSubstract = isTilesView ? tilesToSubstract : columnsToSubstract;

      const row = Math.ceil((selectedProductIndex + 1 + firstRowSubstract) / productsInRow);
      const detailsCardPosition = row * productsInRow;
      const detailsCardPositionCorrected = detailsCardPosition - firstRowSubstract + 1;
      return detailsCardPositionCorrected;
    };

    const detailsCardPosition = isVideoPresent ? getCardPositionWithVideo() : getCardPosition();

    const itemsList = detailsCard
      ? [...productsList.slice(0, detailsCardPosition), detailsCard, ...productsList.slice(detailsCardPosition)]
      : productsList;

    return (
      <section className={classList}>
        {itemsList.map((item) => {
          return item;
        })}
      </section>
    );
  },
  pliPropsAreEqual
);

ProductsListItems.displayName = 'ProductsListItems';

export default ProductsListItems;
