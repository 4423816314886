import classnames from 'classnames';
import React, { useReducer, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { ReactComponent as PlayVideoIcon } from '../../images/icons/play-icon.svg';
import { VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';
import useMediaQuery from '../../utils/useMediaQuery';
import styles from './ProductVideo.module.scss';
import productStyles from '../Product/Product.module.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'DETACH_VIDEO':
      return {
        ...state,
        isDetached: state.shouldBeDetached ? true : false,
      };
    case 'ATTACH_VIDEO':
      return {
        ...state,
        isDetached: false,
      };
    case 'PLAY_VIDEO':
      return {
        ...state,
        shouldBeDetached: true,
        playing: true,
      };
    case 'PAUSE_VIDEO':
      return {
        ...state,
        playing: false,
      };
    case 'SET_HEIGHT':
      return {
        ...state,
        height: action.height,
      };
    case 'CLOSE_DETACHED_VIDEO':
      return {
        ...state,
        isDetached: false,
        shouldBeDetached: false,
        playing: false,
      };
    default:
      return state;
  }
};

const PlayerControls = ({ onClick, playing, title, description }) => {
  return (
    <div
      className={classnames(styles.videoCardOverlay, { [styles.videoCardOverlayHidden]: playing })}
      onClick={onClick}
    >
      <PlayVideoIcon width={75} height={75} viewBox="0 0 80 80" />
      <div className={styles.videoCardText}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const ProductVideo = ({ view, videoUrl, title, description }) => {
  const { isLowerThan, BREAKPOINTS } = useMediaQuery();

  const [state, dispatch] = useReducer(reducer, {
    isDetached: false,
    shouldBeDetached: false,
    playing: false,
    height: null,
  });

  const isMobile = isLowerThan(BREAKPOINTS.MEDIUM);
  const ref = useRef(null);

  const intersectionHandler = (entries) => {
    const isVisible = entries[0].isIntersecting;
    if (isVisible) {
      dispatch({ type: 'ATTACH_VIDEO' });
    } else {
      dispatch({ type: 'DETACH_VIDEO' });
    }
  };

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };
    let observer = new IntersectionObserver(intersectionHandler, options);

    observer.observe(ref.current);
  }, []);

  useEffect(() => {
    const siblingRect = ref.current.nextSibling;

    if (siblingRect) {
      const { height } = siblingRect.getBoundingClientRect();

      dispatch({ type: 'SET_HEIGHT', height });
    }
  }, [view, ref?.current?.nextSibling]);

  const closeDetachedVideo = (e) => {
    e.preventDefault();
    dispatch({ type: 'CLOSE_DETACHED_VIDEO' });
  };

  const playVideo = () => dispatch({ type: 'PLAY_VIDEO' });

  const pauseVideo = () => dispatch({ type: 'PAUSE_VIDEO' });

  const customControls = !isMobile && (
    <PlayerControls
      title={title}
      description={description}
      playing={state.playing}
      onClick={state.playing ? pauseVideo : playVideo}
    />
  );

  const getVideoWidth = () => {
    const videoContainer = ref.current.querySelector('.react-player');
    const { width } = videoContainer.getBoundingClientRect();
    return parseInt(width);
  };

  const widthProp = state.isDetached ? { style: { width: getVideoWidth() } } : {};
  return (
    <div
      className={classnames(productStyles.root, styles.videoCard, {
        [styles.videoCardTile]: view === VIEW_OPTIONS_TILES,
        [styles.videoCardColumn]: view === VIEW_OPTIONS_COLUMNS,
      })}
      data-testid="ProductVideoCard"
      ref={ref}
    >
      <div
        className={classnames(styles.videoCardWrapper, { [styles.videoCardDetached]: state.isDetached })}
        {...widthProp}
      >
        <span className={styles.videoCardClose} onClick={closeDetachedVideo}>
          &times;
        </span>
        {customControls}
        <ReactPlayer
          className="react-player"
          playing={state.playing}
          url={videoUrl}
          onPause={pauseVideo}
          width="100%"
          controls={isMobile}
          height={state.height}
          config={{
            vimeo: {
              playerOptions: {
                api: true,
                controls: isMobile,
                transparent: 0,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProductVideo;
